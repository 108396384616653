import { RefreshDto, createAnonymousToken, refreshToken, sendSmsCode, validateSmsCode } from "@api/authorization";
import { CheckTaskStatusResponse, TaskStatus, checkTaskStatus, createTask } from "@api/generate";
import { upscaleImage, upscaleImageToStoriesFormat } from "@api/upscale";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ym from "react-yandex-metrika";
import { v4 as uuidV4 } from "uuid";
import { GeneratorSetting, ViewMode } from "./models";
import { AppThunkApi } from "./store";

const name = "thunks";

export const ensureDeviceUuidAction = createAsyncThunk<string, void, AppThunkApi>(
  `${name}/ensureDeviceUuidAction`,
  async () => `cvr-${uuidV4()}`,
  {
    condition: (_, thunkApi) => !thunkApi.getState().authorization.deviceUuid,
  }
);

export const ensureAnonymousTokenAction = createAsyncThunk<RefreshDto, void, AppThunkApi>(
  `${name}/ensureAnonymousTokenAction`,
  async (_, thunkApi) => {
    await thunkApi.dispatch(ensureDeviceUuidAction());
    const deviceUuid = thunkApi.getState().authorization.deviceUuid!;
    return await createAnonymousToken({ deviceUuid });
  },
  { condition: (_, thunkApi) => !thunkApi.getState().authorization.tokenData }
);

export const ensureFreshTokenAction = createAsyncThunk<RefreshDto, void, AppThunkApi>(
  `${name}/ensureFreshTokenAction`,
  async (_, thunkApi) => {
    const { tokenData } = thunkApi.getState().authorization;
    const deviceUuid = thunkApi.getState().authorization.deviceUuid!;
    return await refreshToken({ deviceUuid, refreshToken: tokenData!.refreshToken });
  },
  {
    condition: (_, thunkApi) => {
      const { tokenData } = thunkApi.getState().authorization;
      if (!tokenData) {
        return false;
      }
      const now = Date.now();
      const expiration = Date.parse(tokenData.expiresAt);
      return now >= expiration - 10_000;
    },
  }
);

export const sendValidationCodeAction = createAsyncThunk<
  void,
  { phoneNumber: string; formattedPhoneNumber: string },
  AppThunkApi
>(`${name}/sendValidationCodeAction`, async ({ phoneNumber }, thunkApi) => {
  await thunkApi.dispatch(ensureFreshTokenAction());
  const { tokenData, deviceUuid } = thunkApi.getState().authorization;
  await sendSmsCode({ phoneNumber: `7${phoneNumber}`, anonymousToken: tokenData!.token!, deviceUuid: deviceUuid! });
});

export const validateCodeAction = createAsyncThunk<RefreshDto, string, AppThunkApi>(
  `${name}/validateCodeAction`,
  async (code, thunkApi) => {
    await thunkApi.dispatch(ensureFreshTokenAction());
    const { phoneNumber, tokenData, deviceUuid } = thunkApi.getState().authorization;
    return await validateSmsCode({
      phoneNumber: `7${phoneNumber!}`,
      code,
      anonymousToken: tokenData!.token!,
      deviceUuid: deviceUuid!,
    });
  }
);

const userTokenRequired = process.env.FULL_AUTHORIZATION_FLOW_ENABLED === "true";

export const startFlowAction = createAsyncThunk<ViewMode, void, AppThunkApi>(
  `${name}/startFlowAction`,
  async (_, thunkApi) => {
    const { authorization, landing } = thunkApi.getState();
    if (!landing.disclaimerCleared) {
      return ViewMode.DISCLAIMER;
    }
    if (userTokenRequired && authorization.tokenType !== "USER") {
      return ViewMode.AUTHORIZATION;
    }
    return ViewMode.GENERATOR_SETTINGS;
  }
);

export const clearDisclaimerAndStartFlowAction = createAsyncThunk<ViewMode, void, AppThunkApi>(
  `${name}/clearDisclaimerAndStartFlowAction`,
  async (_, thunkApi) => {
    const { authorization } = thunkApi.getState();
    if (userTokenRequired && authorization.tokenType !== "USER") {
      return ViewMode.AUTHORIZATION;
    }
    return ViewMode.GENERATOR_SETTINGS;
  }
);

export const startGenerationAction = createAsyncThunk<string, GeneratorSetting, AppThunkApi>(
  `${name}/startGenerationAction`,
  async (generatorSetting, thunkApi) => {
    await thunkApi.dispatch(ensureFreshTokenAction());
    const authorizationState = thunkApi.getState().authorization;
    const token = authorizationState.tokenData!.token;
    const data = await createTask({
      generatorSetting,
      token,
    });
    return data.task_id;
  }
);

const preloadImage = (src: string): Promise<void> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = () => resolve();
  });
};

export const checkTaskStatusAction = createAsyncThunk<CheckTaskStatusResponse, void, AppThunkApi>(
  `${name}/checkTaskStatusAction`,
  async (_, thunkApi) => {
    await thunkApi.dispatch(ensureFreshTokenAction());
    const authorizationState = thunkApi.getState().authorization;
    const token = authorizationState.tokenData!.token;
    const data = await checkTaskStatus({ taskId: thunkApi.getState().landing.latestTaskId!, token });
    if (data.task_status === TaskStatus.SUCCESS) {
      ym("reachGoal", "generate_image_success");
      const src = data.task_result["url"];
      if (src) {
        await preloadImage(src);
      }
    }
    return data;
  }
);

export const upscaleImageAction = createAsyncThunk<string, void, AppThunkApi>(
  `${name}/upscaleImageAction`,
  async (_, thunkApi) => {
    await thunkApi.dispatch(ensureFreshTokenAction());
    const state = thunkApi.getState();
    if (state.landing.upscaledImageUrl) {
      return state.landing.upscaledImageUrl;
    }
    const imageUrl = state.landing.resultImageUrl;
    const token = state.authorization.tokenData!.token;
    const result = await upscaleImage({ imageUrl: imageUrl!, token });
    return result.url;
  }
);

export const upscaleImageToStoriesAction = createAsyncThunk<string, void, AppThunkApi>(
  `${name}/upscaleImageToStoriesAction`,
  async (_, thunkApi) => {
    await thunkApi.dispatch(ensureFreshTokenAction());
    const state = thunkApi.getState();
    if (state.landing.storiesImageUrl) {
      return state.landing.storiesImageUrl;
    }
    const imageUrl = state.landing.upscaledImageUrl || state.landing.resultImageUrl;
    const token = state.authorization.tokenData!.token;
    const result = await upscaleImageToStoriesFormat({
      imageUrl: imageUrl!,
      token,
      artistName: state.landing.generatorSetting?.subtitle || "",
      trackName: state.landing.generatorSetting?.title || "",
    });
    return result.url;
  }
);
