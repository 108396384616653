import { Button, ButtonProps } from "@mui/material";
import { useAppSelector } from "@store/hooks";
import React from "react";

const BetterButton = ({ children, ...rest }: { children: React.ReactNode } & ButtonProps) => {
  const isSmallHeight = useAppSelector((state) => state.landing.smallHeight);
  return (
    <Button size={isSmallHeight ? "small" : "medium"} {...rest}>
      {children}
    </Button>
  );
};

export default BetterButton;
