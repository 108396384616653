import QrIcon from "@img/QR.png";
import { useAppDispatch } from "@store/hooks";
import { closeAppQrViewAction } from "@store/landing";
import { useCallback } from "react";
import Button from "./BetterButton";
import * as styles from "./QrView.module.scss";

const QrView = () => {
  const dispatch = useAppDispatch();
  const onCloseClick = useCallback(() => {
    dispatch(closeAppQrViewAction());
  }, [dispatch]);
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>QR Code - ПАНЧ</div>
        <div className={styles.subtitle}>Отсканируй QR, чтобы скачать</div>
        <img src={QrIcon} className={styles.qrImage} />
        <Button color="secondary" variant="outlined" fullWidth onClick={onCloseClick}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

export default QrView;
