import { useAppSelector } from "@store/hooks";
import { LayoutMode } from "@store/models";
import BaseDesktopLayout from "./BaseDesktopLayout";
import BaseMobileLayout from "./BaseMobileLayout";
import { BaseLayoutProps } from "./models";

export const BaseLayout = (props: BaseLayoutProps) => {
  const viewMode = useAppSelector((state) => state.landing.layoutMode);
  if (viewMode === LayoutMode.MOBILE) {
    return <BaseMobileLayout {...props} />;
  }
  return <BaseDesktopLayout {...props} />;
};

export default BaseLayout;
