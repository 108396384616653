import punchLogo from "@img/ic-punch-logo.svg";
import * as styles from "./BaseMobileLayout.module.scss";
import DownloadAppButton from "./DownloadAppButton";
import { BaseLayoutProps } from "./models";

const BaseMobileLayout = ({
  imageElement,
  imageSubtitleText,
  headingText,
  subheadingText,
  buttonsElement,
  footerMode,
}: BaseLayoutProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.largeSpacer} />
      <div className={styles.imageContainer}>{imageElement}</div>
      <div className={styles.imageSubtitle}>{imageSubtitleText}</div>
      <div className={styles.heading}>{headingText}</div>
      <div className={styles.subheading}>{subheadingText}</div>
      <div className={styles.smallSpacer} />
      <div>{buttonsElement}</div>
      <div className={styles.largeSpacer} />
      {footerMode === "default" ? (
        <a href="https://punch.ru/" target="_blank" rel="noreferrer noopener">
          <img src={punchLogo} alt="ПАНЧ лого" />
        </a>
      ) : (
        <>
          <DownloadAppButton className={styles.downloadButton} />
          <a href="https://punch.ru/" target="_blank" rel="noreferrer noopener" className={styles.textPunchLink}>
            Подробнее о ПАНЧ
          </a>
        </>
      )}
    </div>
  );
};

export default BaseMobileLayout;
