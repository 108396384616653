import { TaskStatus } from "@api/generate";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GeneratorSetting, LayoutMode, ViewMode } from "./models";
import {
  checkTaskStatusAction,
  clearDisclaimerAndStartFlowAction,
  ensureAnonymousTokenAction,
  ensureFreshTokenAction,
  startFlowAction,
  startGenerationAction,
  upscaleImageAction,
  upscaleImageToStoriesAction,
  validateCodeAction,
} from "./thunks";

interface LandingState {
  layoutMode: LayoutMode;
  smallHeight: boolean;
  generatorSetting: GeneratorSetting | null;
  viewMode: ViewMode;
  viewModeBeforeSettings: ViewMode;
  viewModeBeforeQR: ViewMode;
  latestTaskId: string | null;
  resultImageUrl: string | null;
  upscaledImageUrl: string | null;
  storiesImageUrl: string | null;
  disclaimerCleared: boolean;
  // transient properties
  initializingGeneration: boolean;
  showErrorState: boolean;
}

const defaultState: LandingState = {
  layoutMode: LayoutMode.MOBILE,
  smallHeight: false,
  generatorSetting: null,
  viewMode: ViewMode.DEFAULT,
  viewModeBeforeSettings: ViewMode.DEFAULT,
  viewModeBeforeQR: ViewMode.DEFAULT,
  latestTaskId: null,
  resultImageUrl: null,
  upscaledImageUrl: null,
  storiesImageUrl: null,
  disclaimerCleared: false,
  initializingGeneration: false,
  showErrorState: false,
};

export const maxStoriesTextLength = 64;

const name = "landing";

const landingStateSlice = createSlice({
  name,
  initialState: defaultState,
  reducers: {
    cancelGenerationAction: (state) => {
      state.viewMode = state.viewModeBeforeSettings;
    },
    cancelAuthorizationAction: (state) => {
      state.viewMode = ViewMode.DEFAULT;
    },
    generateOneMoreAction: (state) => {
      state.viewMode = ViewMode.GENERATOR_SETTINGS;
      state.viewModeBeforeSettings = ViewMode.GENERATION_RESULT;
    },
    downloadAction: (state) => {
      state.viewMode = ViewMode.DOWNLOAD;
    },
    cancelDownloadAction: (state) => {
      state.viewMode = ViewMode.GENERATION_RESULT;
    },
    setLayoutModeAction: (state, action: PayloadAction<LayoutMode>) => {
      state.layoutMode = action.payload;
      if (action.payload === LayoutMode.MOBILE && state.viewMode === ViewMode.APP_LINK_QR) {
        state.viewMode = state.viewModeBeforeQR;
      }
    },
    setSmallHeightAction: (state, action: PayloadAction<boolean>) => {
      state.smallHeight = action.payload;
    },
    resetStateAction: (state) => {
      return {
        ...defaultState,
        layoutMode: state.layoutMode,
      };
    },
    openAppQrViewAction: (state) => {
      state.viewModeBeforeQR = state.viewMode;
      state.viewMode = ViewMode.APP_LINK_QR;
    },
    closeAppQrViewAction: (state) => {
      state.viewMode = state.viewModeBeforeQR;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startFlowAction.fulfilled, (state, action) => {
      state.viewModeBeforeSettings = ViewMode.DEFAULT;
      state.viewMode = action.payload;
    });
    builder.addCase(clearDisclaimerAndStartFlowAction.fulfilled, (state, action) => {
      state.disclaimerCleared = true;
      state.viewModeBeforeSettings = ViewMode.DEFAULT;
      state.viewMode = action.payload;
    });
    builder.addCase(validateCodeAction.fulfilled, (state) => {
      state.viewMode = ViewMode.GENERATOR_SETTINGS;
    });
    builder.addCase(startGenerationAction.pending, (state) => {
      state.initializingGeneration = true;
    });
    builder.addCase(startGenerationAction.fulfilled, (state, action) => {
      state.initializingGeneration = false;
      state.latestTaskId = action.payload;
      state.resultImageUrl = null;
      state.storiesImageUrl = null;
      state.upscaledImageUrl = null;
      state.generatorSetting = action.meta.arg;
      state.viewMode = ViewMode.GENERATION_PROGRESS;
    });
    builder.addCase(startGenerationAction.rejected, (state) => {
      state.initializingGeneration = false;
      state.showErrorState = true;
    });
    builder.addCase(checkTaskStatusAction.fulfilled, (state, action) => {
      const { task_status, task_result } = action.payload;
      switch (task_status) {
        case TaskStatus.NOT_FOUND: {
          state.viewMode = ViewMode.DEFAULT;
          state.latestTaskId = null;
          break;
        }
        case TaskStatus.SUCCESS: {
          state.resultImageUrl = task_result["url"]!;
          state.viewMode = ViewMode.GENERATION_RESULT;
          break;
        }
      }
    });
    builder.addCase(upscaleImageAction.fulfilled, (state, action) => {
      state.upscaledImageUrl = action.payload;
    });
    builder.addCase(upscaleImageToStoriesAction.fulfilled, (state, action) => {
      state.storiesImageUrl = action.payload;
    });
    // error handlers
    [
      ensureAnonymousTokenAction.rejected,
      ensureFreshTokenAction.rejected,
      upscaleImageToStoriesAction.rejected,
      upscaleImageAction.rejected,
      checkTaskStatusAction.rejected,
    ].forEach((x) => {
      builder.addCase(x, (state) => {
        state.showErrorState = true;
        if (x === ensureFreshTokenAction.rejected) {
          state.viewMode = ViewMode.DEFAULT;
        }
      });
    });
  },
});

export const {
  cancelGenerationAction,
  generateOneMoreAction,
  setLayoutModeAction,
  cancelAuthorizationAction,
  resetStateAction,
  downloadAction,
  cancelDownloadAction,
  openAppQrViewAction,
  closeAppQrViewAction,
  setSmallHeightAction,
} = landingStateSlice.actions;

export default landingStateSlice.reducer;
