import { useAppDispatch } from "@store/hooks";
import { clearDisclaimerAndStartFlowAction } from "@store/thunks";
import { useCallback } from "react";
import Button from "./BetterButton";
import * as styles from "./DisclaimerView.module.scss";

const DisclaimerView = () => {
  const dispatch = useAppDispatch();
  const onOkClick = useCallback(() => {
    dispatch(clearDisclaimerAndStartFlowAction());
  }, [dispatch]);
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>Нейросеть</div>
        <div className={styles.text}>
          Не знает, что генерирует, и может выдать всякое — если что, не обижайтесь.
          <br />
          Распространяя получившийся контент, помните об ответственности.
        </div>
        <Button fullWidth color="secondary" variant="outlined" onClick={onOkClick}>
          Ок
        </Button>
      </div>
    </div>
  );
};

export default DisclaimerView;
