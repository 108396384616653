import { CssBaseline } from "@mui/material";
import store, { persistor } from "@store/store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { YMInitializer } from "react-yandex-metrika";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app/App";
import AppTheme from "./app/AppTheme";
import DimensObserver from "./app/DimensObserver";
import "./index.module.scss";

const ymId = process.env.YANDEX_METRICA_ID;

ReactDOM.render(
  <>
    {ymId && (
      <YMInitializer
        accounts={[Number.parseInt(ymId)]}
        options={{ webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true, defer: true }}
      />
    )}
    <Provider store={store}>
      <PersistGate loading={<div>...</div>} persistor={persistor}>
        <AppTheme>
          <CssBaseline />
          <App />
          <DimensObserver />
        </AppTheme>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById("root")
);
