import { MoodType, StyleType } from "./models";

export const StyleTypeTranslations: Record<StyleType, string> = {
  [StyleType.ILLUSTRATION]: "Иллюстрация",
  [StyleType.PAINTING]: "Картина",
  [StyleType.PHOTOGRAPHY]: "Фотография",
  [StyleType.PHOTOREALISM]: "Фотореализм",
  [StyleType.THREE_D_RENDER]: "3D рендер",
};

export const MoodTypeTranslations: Record<MoodType, string> = {
  [MoodType.angry]: "Злое",
  [MoodType.calm]: "Спокойное",
  [MoodType.happy]: "Счастливое",
  [MoodType.romantic]: "Романтическое",
  [MoodType.chill]: "На чилле",
  [MoodType.dark]: "Мрачное",
  [MoodType.dreamy]: "Мечтательное",
  [MoodType.energetic]: "Энергичное",
  [MoodType.sad]: "Грустное",
  [MoodType.melancholic]: "Меланхоличное",
  [MoodType.relaxing]: "Расслабленное",
  [MoodType.peaceful]: "Умиротворенное",
  [MoodType.upbeat]: "Оптимистичное",
  [MoodType.uplifting]: "Воодушевляющее",
  [MoodType.scary]: "Страшное",
  [MoodType.creepy]: "Криповое",
  [MoodType.intense]: "Напряженное",
  [MoodType.epic]: "Эпическое",
  [MoodType.mysterious]: "Загадочное",
};
