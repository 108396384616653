import { Fade, Slide } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { resetStateAction } from "@store/landing";
import { LayoutMode, ViewMode } from "@store/models";
import { ensureAnonymousTokenAction } from "@store/thunks";
import { useEffect } from "react";
import ym from "react-yandex-metrika";
import * as styles from "./App.module.scss";
import AuthorizationView from "./AuthorizationView";
import DefaultView from "./DefaultView";
import DisclaimerView from "./DisclaimerView";
import DownloadView from "./DownloadView";
import ErrorView from "./ErrorView";
import GenerationProgressView from "./GenerationProgressView";
import GenerationResultView from "./GenerationResultView";
import { BackgroundImage, SampleBackgroundContextProvider } from "./Landing/SampleBackgroundsContext";
import QrView from "./QrView";
import SettingsView from "./SettingsView";

const userTokenRequired = process.env.FULL_AUTHORIZATION_FLOW_ENABLED === "true";

const App = () => {
  const { viewMode, layoutMode, showErrorState } = useAppSelector((state) => state.landing);
  const authorization = useAppSelector((state) => state.authorization);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(ensureAnonymousTokenAction());
  }, [dispatch]);
  useEffect(() => {
    if (viewMode !== ViewMode.DEFAULT && userTokenRequired && authorization.tokenType !== "USER") {
      dispatch(resetStateAction());
    }
    // only on mount in case authorization was enabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ModalTransitionComponent = layoutMode === LayoutMode.MOBILE ? Slide : Fade;
  useEffect(() => {
    ym("hit", `/${viewMode.toLowerCase()}`);
  }, [viewMode]);
  /**
   * below order is important!
   * all ModalTransitionComponent transitions (so modals/drawers) should come after fade transition,
   * and relative order of siblings should be like order of screens appearance
   */
  if (showErrorState) {
    return (
      <div className={styles.root}>
        <SampleBackgroundContextProvider>
          <BackgroundImage />
          <ErrorView />
        </SampleBackgroundContextProvider>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <SampleBackgroundContextProvider>
        <BackgroundImage />
        <Fade in={viewMode === ViewMode.DEFAULT} mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <DefaultView />
          </div>
        </Fade>
        <Fade in={viewMode === ViewMode.DISCLAIMER} mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <DisclaimerView />
          </div>
        </Fade>
        <Fade in={viewMode === ViewMode.GENERATION_PROGRESS} mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <GenerationProgressView />
          </div>
        </Fade>
        <Fade in={viewMode === ViewMode.GENERATION_RESULT} mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <GenerationResultView />
          </div>
        </Fade>
        <Fade in={viewMode === ViewMode.APP_LINK_QR} mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <QrView />
          </div>
        </Fade>
        <ModalTransitionComponent
          in={viewMode === ViewMode.GENERATOR_SETTINGS}
          direction="up"
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.item}>
            <SettingsView />
          </div>
        </ModalTransitionComponent>
        <ModalTransitionComponent in={viewMode === ViewMode.AUTHORIZATION} direction="up" mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <AuthorizationView />
          </div>
        </ModalTransitionComponent>
        <ModalTransitionComponent in={viewMode === ViewMode.DOWNLOAD} direction="up" mountOnEnter unmountOnExit>
          <div className={styles.item}>
            <DownloadView />
          </div>
        </ModalTransitionComponent>
      </SampleBackgroundContextProvider>
    </div>
  );
};

export default App;
