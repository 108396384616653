import useCountdown from "@hooks/useCountdown";
import { Checkbox } from "@mui/material";
import { reenterPhoneNumberAction } from "@store/authorization";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { AuthorizationPhase } from "@store/models";
import { sendValidationCodeAction, validateCodeAction } from "@store/thunks";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useIMask } from "react-imask";
import AuthorizationLayout from "./Authorization/AuthorizationLayout";
import * as styles from "./AuthorizationView.module.scss";
import Button from "./BetterButton";

const phoneImaskOpts = {
  mask: "000 000 0000",
};

const PhoneNumberStateView = () => {
  const { phoneNumber, codeRequestError } = useAppSelector((state) => state.authorization);
  const { ref, value, unmaskedValue, setUnmaskedValue } = useIMask(phoneImaskOpts);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  useEffect(() => {
    setDisplayError(!!codeRequestError);
  }, [codeRequestError]);
  useEffect(() => {
    setDisplayError(false);
  }, [value]);
  useEffect(() => {
    if (phoneNumber) {
      setUnmaskedValue(phoneNumber);
    }
    // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useAppDispatch();
  return (
    <AuthorizationLayout
      inputElement={
        <>
          <span className={styles.phoneNumberPrefix}>+7</span>
          <input ref={ref} className={styles.phoneNumberInput} type="tel" inputMode="numeric" placeholder="телефон" />
        </>
      }
      inputSubtitle={
        displayError ? (
          <span className={styles.error}>
            Привет! У нас тут небольшие технические неполадки, попробуй повторить через час
          </span>
        ) : (
          "Номер твоего аккаунта ПАНЧ"
        )
      }
      buttonElement={
        <Button
          fullWidth
          variant="contained"
          disabled={!termsAccepted || !unmaskedValue || unmaskedValue.length < 10}
          onClick={() =>
            dispatch(sendValidationCodeAction({ phoneNumber: unmaskedValue, formattedPhoneNumber: value }))
          }
        >
          Получить код
        </Button>
      }
      footerElement={
        <>
          <Checkbox
            className={styles.termsCheckbox}
            checked={termsAccepted}
            onChange={(_: any, checked: boolean) => setTermsAccepted(checked)}
          />
          <div className={styles.termsAndConditions}>
            Я принимаю{" "}
            <a href="https://punch.ru/legal/eua" target="_blank" rel="noreferrer noopener">
              Пользовательское соглашение
            </a>{" "}
            и{" "}
            <a href="https://punch.ru/legal/policy" target="_blank" rel="noreferrer noopener">
              Политику конфиденциальности
            </a>
          </div>
        </>
      }
    />
  );
};

const codeImaskOpts = {
  mask: "000000",
};

const codeLength = 6;

const CodeStateView = () => {
  const { formattedPhoneNumber, phoneNumber, codeValidationError, codeValidationNetworkRequestId } = useAppSelector(
    (state) => state.authorization
  );
  const { ref, unmaskedValue } = useIMask(codeImaskOpts);
  const countdownValue = useCountdown({ countdownTo: 59, enabled: true, key: codeValidationNetworkRequestId });
  const dispatch = useAppDispatch();
  useEffect(() => {
    setDisplayError(false);
    if (unmaskedValue.length === codeLength) {
      dispatch(validateCodeAction(unmaskedValue));
    }
  }, [dispatch, unmaskedValue]);
  const [displayError, setDisplayError] = useState(false);
  useEffect(() => {
    if (codeValidationError) {
      setDisplayError(true);
    }
  }, [codeValidationError]);
  return (
    <AuthorizationLayout
      inputElement={
        <div className={styles.codeContainer}>
          {unmaskedValue && unmaskedValue.length > 0 && unmaskedValue.length < codeLength && (
            <span className={styles.codeUnderlay}>
              {unmaskedValue}
              {"XXXXXX".substring(0, codeLength - unmaskedValue.length)}
            </span>
          )}
          <input
            ref={ref}
            autoComplete="one-time-code"
            className={classNames(styles.phoneNumberInput, displayError && styles.error)}
            type="tel"
            inputMode="numeric"
            placeholder="Код из СМС"
          />
        </div>
      }
      inputSubtitle={
        displayError ? (
          <span className={classNames(displayError && styles.error)}>
            {codeValidationError?.message || codeValidationError?.stack || "Упс, что-то пошло не так"}
          </span>
        ) : (
          `Код отправлен на номер +7 ${formattedPhoneNumber}`
        )
      }
      buttonElement={
        countdownValue > 0 ? (
          <Button fullWidth variant="contained" disabled>
            Повторная отправка через 00:{`${countdownValue < 10 ? 0 : ""}${countdownValue}`}
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() =>
              dispatch(
                sendValidationCodeAction({ phoneNumber: phoneNumber!, formattedPhoneNumber: formattedPhoneNumber! })
              )
            }
          >
            Отправить повторно
          </Button>
        )
      }
      footerElement={
        <Button fullWidth color="secondary" onClick={() => dispatch(reenterPhoneNumberAction())}>
          Ошибся номером
        </Button>
      }
    />
  );
};

const AuthorizationView = () => {
  const phase = useAppSelector((state) => state.authorization.authorizationPhase);
  if (phase === AuthorizationPhase.PHONE_NUMBER) {
    return <PhoneNumberStateView />;
  }
  return <CodeStateView />;
};

export default AuthorizationView;
