import { Fade } from "@mui/material";
import { useAppSelector } from "@store/hooks";
import { ViewMode } from "@store/models";
import classNames from "classnames";
import { createContext, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import SwiperType, { Autoplay, Virtual } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/virtual";
import { Swiper, SwiperSlide } from "swiper/react";
import * as styles from "./SampleBackgroundsContext.module.scss";

const coverImages = [...new Array(15)].map((_, index) => `samples/0${index < 9 ? "0" : ""}${index + 1}.jpg`);

interface SampleBackgroundContextType {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}
const SampleBackgroundContext = createContext<SampleBackgroundContextType>({
  currentIndex: 0,
  setCurrentIndex: () => {
    // noop
  },
});

export default SampleBackgroundContext;

export const SampleBackgroundContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const contextValue = useMemo<SampleBackgroundContextType>(
    () => ({
      currentIndex,
      setCurrentIndex,
    }),
    [currentIndex, setCurrentIndex]
  );
  return <SampleBackgroundContext.Provider value={contextValue}>{children}</SampleBackgroundContext.Provider>;
};
const useSwiperRef = () => {
  const { currentIndex } = useContext(SampleBackgroundContext);
  const swiperRef = useRef<SwiperType | null>(null);
  const onSwiper = useCallback((instance: SwiperType) => {
    swiperRef.current = instance;
    if (currentIndex) {
      instance.slideToLoop(currentIndex, 0);
    }
    // deps are skipped cuz we need to sync with index only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { swiperRef, onSwiper };
};

export const SampleCoverImage = ({ className, imageClassName }: { className: string; imageClassName: string }) => {
  const { setCurrentIndex } = useContext(SampleBackgroundContext);
  const onActiveIndexChange = useCallback(
    (swiper: SwiperType) => {
      setCurrentIndex(swiper.activeIndex);
    },
    [setCurrentIndex]
  );
  const { onSwiper } = useSwiperRef();
  return (
    <Swiper
      className={classNames(className, styles.imageContainer)}
      onSwiper={onSwiper}
      onActiveIndexChange={onActiveIndexChange}
      slidesPerView={1}
      spaceBetween={0}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Virtual]}
      virtual
    >
      {coverImages.map((x, index) => (
        <SwiperSlide key={x} virtualIndex={index}>
          <img src={x} className={imageClassName} alt="ПАНЧ генерация обложек" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export const BackgroundImage = () => {
  const { viewMode, viewModeBeforeSettings, viewModeBeforeQR, resultImageUrl } = useAppSelector(
    (state) => state.landing
  );
  switch (viewMode) {
    case ViewMode.DEFAULT:
    case ViewMode.AUTHORIZATION: {
      return <SwiperBackgroundImage />;
    }
    case ViewMode.GENERATION_PROGRESS: {
      return <SimpleBackgroundImage src={require("@img/mock_bg.png")} />;
    }
    case ViewMode.GENERATION_RESULT:
    case ViewMode.DOWNLOAD: {
      return <SimpleBackgroundImage src={resultImageUrl!} />;
    }
    case ViewMode.GENERATOR_SETTINGS: {
      if (viewModeBeforeSettings === ViewMode.GENERATION_RESULT) {
        return <SimpleBackgroundImage src={resultImageUrl!} />;
      }
      return <SwiperBackgroundImage />;
    }
    case ViewMode.APP_LINK_QR: {
      if (viewModeBeforeQR === ViewMode.GENERATION_RESULT) {
        return <SimpleBackgroundImage src={resultImageUrl!} />;
      } else if (viewModeBeforeQR === ViewMode.GENERATION_PROGRESS) {
        return <SimpleBackgroundImage src={require("@img/mock_bg.png")} />;
      }
      return <SwiperBackgroundImage />;
    }
  }
  return <SwiperBackgroundImage />;
};

const SimpleBackgroundImage = ({ src }: { src: string }) => {
  return (
    <div className={styles.backgroundSwiper}>
      <img src={src} className={styles.backgroundImage} alt="ПАНЧ генерация обложек" />
      <div className={styles.backgroundImageOverlay} />
    </div>
  );
};

const SwiperBackgroundImage = () => {
  const { currentIndex } = useContext(SampleBackgroundContext);
  const currentIndexRef = useRef(currentIndex);
  const [prevIndex, setPrevIndex] = useState(0);
  useLayoutEffect(() => {
    setPrevIndex(currentIndexRef.current);
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);
  const currentImage = coverImages[currentIndex];
  const previousImage = coverImages[prevIndex];
  return (
    <div className={styles.backgroundSwiper}>
      <img src={previousImage} className={styles.backgroundImage} alt="ПАНЧ генерация обложек" />
      <Fade key={currentIndex} in timeout={250} easing="ease-out">
        <img src={currentImage} className={styles.backgroundImage} alt="ПАНЧ генерация обложек" />
      </Fade>
      <div className={styles.backgroundImageOverlay} />
    </div>
  );
};
