import { largeWidth, mediumWidth, smallWidth } from "partials/_breakpoints-export.scss";
import { useEffect, useState } from "react";

export enum Breakpoint {
  SM = "SM",
  MD = "MD",
  LG = "LG",
}

export enum MatchMode {
  GT = "GT",
  LT = "LT",
}

const breakpointToWidthMap = {
  [Breakpoint.SM]: smallWidth,
  [Breakpoint.MD]: mediumWidth,
  [Breakpoint.LG]: largeWidth,
} as Record<Breakpoint, string>;

const pickQuery = (breakpoint: Breakpoint, matchMode: MatchMode): string => {
  const width = breakpointToWidthMap[breakpoint];
  if (matchMode === MatchMode.GT) {
    return `(min-width:${width})`;
  }
  return `(max-width:${width})`;
};

const useQueryMatch = (query: string) => {
  const [matches, setMatches] = useState<boolean>(() => window.matchMedia(query).matches);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    setMatches(matchMedia.matches);
    const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
    matchMedia.addEventListener("change", listener);
    return () => {
      matchMedia.removeEventListener("change", listener);
    };
  }, [query]);

  return matches;
};

/**
 *
 * @param breakpoint one of possible breakpoints
 * @param matchMode GT will check max-width, LT - min-width
 * @returns
 */
const useBreakpointMatch = (breakpoint: Breakpoint, matchMode: MatchMode): boolean => {
  const query = pickQuery(breakpoint, matchMode);
  return useQueryMatch(query);
};

const smallHeightQuery = "(max-height: 667px)";
export const useIsSmallHeight = (): boolean => useQueryMatch(smallHeightQuery);

export default useBreakpointMatch;
