import { useAppDispatch } from "@store/hooks";
import { startFlowAction } from "@store/thunks";
import { useCallback } from "react";
import Button from "./BetterButton";
import * as styles from "./DefaultView.module.scss";
import BaseLayout from "./Landing/BaseLayout";
import { SampleCoverImage } from "./Landing/SampleBackgroundsContext";

const DefaultView = () => {
  const dispatch = useAppDispatch();
  const onGenerateClick = useCallback(() => {
    dispatch(startFlowAction());
  }, [dispatch]);
  return (
    <BaseLayout
      imageElement={<SampleCoverImage className={styles.imageContainer} imageClassName={styles.image} />}
      imageSubtitleText="пример обложки"
      headingText="Генерация обложек"
      subheadingText="Быстро и просто"
      buttonsElement={
        <Button className={styles.generateButton} color="primary" variant="contained" onClick={onGenerateClick}>
          Сгенерировать обложку
        </Button>
      }
      footerMode="default"
    />
  );
};

export default DefaultView;
