// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RT7bcTD_mxjKJMZRMiBW{width:280px;height:64px}.ikiflvXiN3G0PMU9CWnt{width:100%;height:100%;-o-object-fit:contain;object-fit:contain}.RT7bcTD_mxjKJMZRMiBW{cursor:pointer;transition:opacity .1s ease-in-out}.RT7bcTD_mxjKJMZRMiBW:hover{opacity:.8}", "",{"version":3,"sources":["webpack://./src/app/Landing/DownloadAppButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CACG,kBAAA,CAGL,sBACE,cAAA,CACA,kCAAA,CAEA,4BACE,UAAA","sourcesContent":[".root {\n  width: 280px;\n  height: 64px;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  -o-object-fit: contain;\n     object-fit: contain;\n}\n\n.root {\n  cursor: pointer;\n  transition: opacity 0.1s ease-in-out;\n\n  &:hover {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var root = "RT7bcTD_mxjKJMZRMiBW";
export var image = "ikiflvXiN3G0PMU9CWnt";
export default ___CSS_LOADER_EXPORT___;
