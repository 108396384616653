import useBreakpointMatch, { Breakpoint, MatchMode, useIsSmallHeight } from "@hooks/useBreakpointMatch";
import { useAppDispatch } from "@store/hooks";
import { setLayoutModeAction, setSmallHeightAction } from "@store/landing";
import { LayoutMode } from "@store/models";
import { useLayoutEffect } from "react";

const DimensObserver = () => {
  const dispatch = useAppDispatch();

  const isSmallWidth = useBreakpointMatch(Breakpoint.SM, MatchMode.LT);
  useLayoutEffect(() => {
    dispatch(setLayoutModeAction(isSmallWidth ? LayoutMode.MOBILE : LayoutMode.DESKTOP));
  }, [isSmallWidth, dispatch]);
  const isSmallHeight = useIsSmallHeight();
  useLayoutEffect(() => {
    dispatch(setSmallHeightAction(isSmallHeight));
  }, [dispatch, isSmallHeight]);
  return null;
};

export default DimensObserver;
