import randomIcon from "@img/ic-random.svg";
import clearIcon from "@img/ic_clear.svg";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, SelectProps, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { cancelGenerationAction, maxStoriesTextLength } from "@store/landing";
import {
  defaultSetting,
  GeneratorSetting,
  GenreType,
  LayoutMode,
  makeRandomSetting,
  MoodType,
  StyleType,
} from "@store/models";
import { startGenerationAction } from "@store/thunks";
import { MoodTypeTranslations, StyleTypeTranslations } from "@store/translations";
import sortBy from "lodash.sortby";
import { useCallback, useState } from "react";
import ym from "react-yandex-metrika";
import Button from "./BetterButton";
import * as styles from "./SettingsView.module.scss";

const mobileSelectProps: Partial<SelectProps> = {
  autoWidth: true,
  MenuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
};

const capitalizeString = (source: string): string =>
  source
    .split(" ")
    .map((x) => `${x[0].toUpperCase()}${x.substring(1)}`)
    .join(" ");

const sortedGenres = sortBy(Object.values(GenreType), (x) => x.toLowerCase());
const sortedMoods = sortBy(Object.values(MoodType), (x) => MoodTypeTranslations[x]);
const sortedStyles = sortBy(Object.values(StyleType), (x) => StyleTypeTranslations[x]);

const genreNames = Object.values(GenreType).reduce((a, x) => {
  a[x] = capitalizeString(x);
  return a;
}, {} as Record<GenreType, string>);

const SettingsView = () => {
  const { layoutMode, initializingGeneration, generatorSetting } = useAppSelector((state) => state.landing);
  const [setting, setSetting] = useState<GeneratorSetting>(generatorSetting || defaultSetting);
  const dispatch = useAppDispatch();
  const onGenerateClick = useCallback(() => {
    ym("reachGoal", "generate_image_start");
    dispatch(startGenerationAction(setting));
  }, [dispatch, setting]);
  const onCancelClick = useCallback(() => {
    dispatch(cancelGenerationAction());
  }, [dispatch]);
  const selectProps = layoutMode === LayoutMode.MOBILE ? mobileSelectProps : undefined;
  const onGenerateRandomClick = useCallback(() => {
    setSetting((current) => makeRandomSetting(current));
  }, []);
  const onClearClick = useCallback(() => {
    setSetting(defaultSetting);
  }, []);
  return (
    <div className={styles.root}>
      <div className={styles.dialogTitleRow}>
        <div className={styles.dialogTitleText}>Генерация обложки</div>
        <IconButton size="small" onClick={onCancelClick} className={styles.mobileCloseButton}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      <IconButton size="small" onClick={onCancelClick} className={styles.desktopCloseButton}>
        <CloseIcon />
      </IconButton>
      <div className={styles.dialogContent}>
        <div className={styles.dialogSectionTitle}>
          Текст обложки <span className={styles.gray}>(формат сторис)</span>
        </div>
        <div className={styles.storiesDialogSection}>
          <TextField
            value={setting.title || ""}
            onChange={(e) => setSetting({ ...setting, title: e.target.value.substring(0, maxStoriesTextLength) })}
            label="Название трека"
            fullWidth
            size="small"
          />
          <TextField
            value={setting.subtitle || ""}
            onChange={(e) => setSetting({ ...setting, subtitle: e.target.value.substring(0, maxStoriesTextLength) })}
            label="Имя артиста"
            fullWidth
            size="small"
          />
        </div>
        <div className={styles.dialogSectionTitle}>Основное</div>
        <div className={styles.mainDialogSection}>
          <TextField
            value={setting.content}
            onChange={(e) => setSetting({ ...setting, content: e.target.value })}
            label="Опиши, что хочешь увидеть на обложке"
            placeholder="Лягушка в наушниках поет на сцене в фильме Тарантино"
            fullWidth
            size="small"
            error={!setting.content?.trim()}
            inputProps={{
              autoCapitalize: "none",
              autoCorrect: "none",
              autofill: "off",
              id: "whatever",
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl fullWidth size="small">
            <InputLabel id="genre-select-label">Жанр музыки</InputLabel>
            <Select
              labelId="genre-select-label"
              id="genre-select"
              value={setting.genre}
              label="Жанр музыки"
              multiple
              onChange={(e) => setSetting({ ...setting, genre: e.target.value as GenreType[] })}
              {...selectProps}
              renderValue={(selected) => (selected as GenreType[]).map((x) => genreNames[x]).join(", ")}
            >
              {sortedGenres.map((x) => (
                <MenuItem key={x} value={x}>
                  <Checkbox checked={setting.genre.indexOf(x) > -1} />
                  {genreNames[x]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="mood-select-label">Настроение</InputLabel>
            <Select
              labelId="mood-select-label"
              id="mood-select"
              value={setting.mood}
              label="Настроения"
              multiple
              onChange={(e) => setSetting({ ...setting, mood: e.target.value as MoodType[] })}
              {...selectProps}
              renderValue={(selected) => (selected as MoodType[]).map((x) => MoodTypeTranslations[x]).join(", ")}
            >
              {sortedMoods.map((x) => (
                <MenuItem key={x} value={x}>
                  <Checkbox checked={setting.mood.indexOf(x) > -1} />
                  {MoodTypeTranslations[x]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={styles.dialogSectionTitle}>Стиль обложки</div>
        <FormControl fullWidth size="small">
          <InputLabel id="style-select-label">Стиль обложки</InputLabel>
          <Select
            labelId="style-select-label"
            id="style-select"
            value={setting.style || ""}
            label="Стиль обложки"
            onChange={(e) => setSetting({ ...setting, style: e.target.value as StyleType })}
            {...selectProps}
            error={!setting.style}
          >
            {sortedStyles.map((x) => (
              <MenuItem key={x} value={x}>
                {StyleTypeTranslations[x]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          className={styles.checkboxReplacement}
          onClick={() => setSetting({ ...setting, punchify: !setting.punchify })}
        >
          <div className={styles.checkboxTitle}>Сделать в стиле ПАНЧ</div>
          <Checkbox checked={setting.punchify} />
        </div>
      </div>
      <div className={styles.dialogButtons}>
        <Button variant="outlined" color="secondary" onClick={onClearClick} disabled={initializingGeneration}>
          <img src={clearIcon} />
        </Button>
        <Button variant="outlined" color="secondary" onClick={onGenerateRandomClick} disabled={initializingGeneration}>
          <img src={randomIcon} />
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!setting.content?.trim() || !setting.style || initializingGeneration}
          onClick={onGenerateClick}
        >
          Сгенерировать
        </Button>
      </div>
    </div>
  );
};

export default SettingsView;
