import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authorizationReducer from "./authorization";
import landingReducer from "./landing";

const makePersistKey = (name: string): string => `${process.env.STAND}:2:${name}`;

const reducer = combineReducers({
  landing: persistReducer(
    { key: makePersistKey("landing"), storage, blacklist: ["initializingGeneration", "showErrorState"] },
    landingReducer
  ),
  authorization: persistReducer(
    { key: makePersistKey("authv2"), storage, whitelist: ["tokenData", "tokenType", "deviceUuid"] },
    authorizationReducer
  ),
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer, // important to keep it that way, or RootState type will become any
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export interface AppThunkApi {
  state: RootState;
  dispatch: AppDispatch;
}
