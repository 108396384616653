import { useAppDispatch, useAppSelector } from "@store/hooks";
import { downloadAction, generateOneMoreAction, maxStoriesTextLength } from "@store/landing";
import { LayoutMode } from "@store/models";
import { useCallback } from "react";
import Button from "./BetterButton";
import * as styles from "./GenerationResultView.module.scss";
import BaseLayout from "./Landing/BaseLayout";

const GenerationResultView = () => {
  const { resultImageUrl, layoutMode, generatorSetting } = useAppSelector((state) => state.landing);
  const dispatch = useAppDispatch();
  const onCreateMoreClick = useCallback(() => {
    dispatch(generateOneMoreAction());
  }, [dispatch]);
  const onDownloadClick = useCallback(() => {
    dispatch(downloadAction());
  }, [dispatch]);
  return (
    <BaseLayout
      imageElement={
        <div>
          <img src={resultImageUrl!} className={styles.resultImage} />
        </div>
      }
      imageSubtitleText="твоя обложка"
      headingText={
        layoutMode === LayoutMode.DESKTOP
          ? "Генерация обложек"
          : generatorSetting?.title?.substring(0, maxStoriesTextLength) || ""
      }
      subheadingText={
        layoutMode === LayoutMode.DESKTOP
          ? "Быстро и просто"
          : generatorSetting?.subtitle?.substring(0, maxStoriesTextLength) || ""
      }
      buttonsElement={
        <div className={styles.buttonsContainer}>
          <Button variant="contained" color="success" fullWidth onClick={onDownloadClick}>
            Скачать
          </Button>
          <Button variant="outlined" color="secondary" fullWidth onClick={onCreateMoreClick}>
            Создать еще
          </Button>
        </div>
      }
      desktopSecondaryHeadingText={generatorSetting?.title?.substring(0, maxStoriesTextLength)}
      desktopSecondarySubheadingText={generatorSetting?.subtitle?.substring(0, maxStoriesTextLength)}
      footerMode="download"
    />
  );
};

export default GenerationResultView;
