import { defaultResponseHandler } from "./utils";

export interface UserDto {
  id: number;
  nickName: string;
}

export interface RefreshDto {
  token: string;
  refreshToken: string;
  expiresAt: string;
}

export interface SecurityTokenDto extends RefreshDto {
  user: UserDto;
}

export const createAnonymousToken = (request: { deviceUuid: string }): Promise<SecurityTokenDto> =>
  fetch(`${process.env.API_URL}/api/v1/auth/login/anonymous`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(defaultResponseHandler);

export const refreshToken = ({
  deviceUuid,
  refreshToken,
}: {
  deviceUuid: string;
  refreshToken: string;
}): Promise<RefreshDto> =>
  fetch(`${process.env.API_URL}/api/v1/auth/refresh`, {
    method: "POST",
    body: JSON.stringify({ deviceUuid, refreshToken }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  }).then(defaultResponseHandler);

export const sendSmsCode = ({
  deviceUuid,
  phoneNumber,
  anonymousToken,
}: {
  deviceUuid: string;
  /** 11 digits */
  phoneNumber: string;
  anonymousToken: string;
}): Promise<void> =>
  fetch(`${process.env.API_URL}/api/v1/auth/login/phone/request-code`, {
    method: "POST",
    body: JSON.stringify({ deviceUuid, phoneNumber }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${anonymousToken}`,
    },
  }).then(defaultResponseHandler);

export const validateSmsCode = ({
  deviceUuid,
  phoneNumber,
  code,
  anonymousToken,
}: {
  deviceUuid: string;
  /** 11 digits */
  phoneNumber: string;
  code: string;
  anonymousToken: string;
}): Promise<SecurityTokenDto> =>
  fetch(`${process.env.API_URL}/api/v1/auth/login/phone/confirm-code`, {
    method: "POST",
    body: JSON.stringify({ deviceUuid, phoneNumber, code }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${anonymousToken}`,
    },
  }).then(defaultResponseHandler);
