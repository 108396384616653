import { GeneratorSetting } from "@store/models";
import { defaultResponseHandler, jsonContentHeaders } from "./utils";

export interface CreateTaskResponse {
  task_id: string;
}

export enum TaskStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  NOT_FOUND = "NOT_FOUND",
}
export interface CheckTaskStatusResponse {
  task_id: string;
  task_status: TaskStatus;
  task_result: Record<string, string>;
}

export const createTask = ({
  generatorSetting,
  token,
}: {
  generatorSetting: GeneratorSetting;
  token: string;
}): Promise<CreateTaskResponse> =>
  fetch(`${process.env.API_URL}/api/v1/cover-generator/tasks`, {
    method: "POST",
    body: JSON.stringify(generatorSetting),
    headers: {
      Authorization: `Bearer ${token}`,
      ...jsonContentHeaders,
    },
  }).then(defaultResponseHandler);

export const checkTaskStatus = ({
  taskId,
  token,
}: {
  taskId: string;
  token: string;
}): Promise<CheckTaskStatusResponse> =>
  fetch(`${process.env.API_URL}/api/v1/cover-generator/tasks/${taskId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(defaultResponseHandler);
