// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bfyPioKigx0_E5zTCBwF{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(26,26,26,.7);display:flex;flex-direction:column;padding:0 16px 16px;align-items:center;justify-content:center}.iMFK77lknUvFDCV2iehp{height:321px;width:auto;margin-bottom:40px}.nD1TQJ3kgasSELIhiAiG,.qwsEAoz5FwqUBZ_uLfPH{text-align:center;max-width:420px}.nD1TQJ3kgasSELIhiAiG{font-weight:900;font-size:18px;line-height:24px;font-family:\"DrukWideCyr\";text-transform:uppercase;color:#fff;margin-bottom:8px}.qwsEAoz5FwqUBZ_uLfPH{font-size:16px;line-height:20px;color:rgba(255,255,255,.7)}", "",{"version":3,"sources":["webpack://./src/app/ErrorView.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,kCAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CAEE,iBAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,yBAAA,CACA,wBAAA,CACA,UAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,0BAAA","sourcesContent":["@use \"~partials/breakpoints\";\n\n.root {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(26, 26, 26, 0.7);\n  display: flex;\n  flex-direction: column;\n  padding: 0 16px 16px;\n  align-items: center;\n  justify-content: center;\n}\n\n.image {\n  height: 321px;\n  width: auto;\n  margin-bottom: 40px;\n}\n\n.title,\n.subtitle {\n  text-align: center;\n  max-width: 420px;\n}\n\n.title {\n  font-weight: 900;\n  font-size: 18px;\n  line-height: 24px;\n  font-family: \"DrukWideCyr\";\n  text-transform: uppercase;\n  color: #ffffff;\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  font-size: 16px;\n  line-height: 20px;\n  color: rgba(255, 255, 255, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
export var root = "bfyPioKigx0_E5zTCBwF";
export var image = "iMFK77lknUvFDCV2iehp";
export var title = "nD1TQJ3kgasSELIhiAiG";
export var subtitle = "qwsEAoz5FwqUBZ_uLfPH";
export default ___CSS_LOADER_EXPORT___;
