import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { IconButton, Radio } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { cancelDownloadAction } from "@store/landing";
import { upscaleImageAction, upscaleImageToStoriesAction } from "@store/thunks";
import classNames from "classnames";
import { useCallback, useState } from "react";
import ym from "react-yandex-metrika";
import * as styles from "./DownloadView.module.scss";

enum ImageType {
  DEFAULT = "DEFAULT",
  UPSCALED = "UPSCALED",
  STORY = "STORY",
}

const imageTypeTitle = {
  [ImageType.DEFAULT]: "Квадрат 512px",
  [ImageType.UPSCALED]: "Квадрат 3000px",
  [ImageType.STORY]: "Формат сторис",
};

const imageTypeSubtitle = {
  [ImageType.DEFAULT]: "Стандартный размер обложки",
  [ImageType.UPSCALED]: "Подойдет для стриминговых сервисов",
  [ImageType.STORY]: "Удобно делиться в соц. сетях",
};

const OptionView = ({
  type,
  active,
  activateOption,
}: {
  type: ImageType;
  active: boolean;
  activateOption: (type: ImageType) => void;
}) => {
  return (
    <div className={styles.option} onClick={() => activateOption(type)}>
      <div className={classNames(styles.optionTitle, active && styles.optionTitleActive)}>{imageTypeTitle[type]}</div>
      <div className={styles.optionSubtitle}>{imageTypeSubtitle[type]}</div>
      <Radio className={styles.radio} color="primary" checked={active} />
    </div>
  );
};

const DownloadView = () => {
  const { resultImageUrl } = useAppSelector((state) => state.landing);
  const dispatch = useAppDispatch();
  const onCancelClick = useCallback(() => {
    dispatch(cancelDownloadAction());
  }, [dispatch]);
  const [imageType, setImageType] = useState(ImageType.DEFAULT);
  const [inProgress, setInProgress] = useState(false);
  const onDownloadClick = useCallback(() => {
    const win: Window = window;
    switch (imageType) {
      case ImageType.DEFAULT: {
        if (resultImageUrl) {
          ym("reachGoal", "download_image", {
            image_type: "default",
          });
          win.location = resultImageUrl;
        }
        break;
      }
      case ImageType.STORY: {
        setInProgress(true);
        dispatch(upscaleImageToStoriesAction()).then((result) => {
          setInProgress(false);
          if (upscaleImageToStoriesAction.fulfilled.match(result)) {
            ym("reachGoal", "download_image", {
              image_type: "story",
            });
            win.location = result.payload;
          }
        });
        break;
      }
      case ImageType.UPSCALED: {
        setInProgress(true);
        dispatch(upscaleImageAction()).then((result) => {
          setInProgress(false);
          if (upscaleImageAction.fulfilled.match(result)) {
            ym("reachGoal", "download_image", {
              image_type: "upscaled",
            });
            win.location = result.payload;
          }
        });
      }
    }
  }, [dispatch, imageType, resultImageUrl]);
  return (
    <div className={styles.root}>
      <div className={styles.dialogTitleRow}>
        <div className={styles.dialogTitleText}>Скачивание обложки</div>
        <IconButton size="small" onClick={onCancelClick} className={styles.mobileCloseButton}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      <IconButton size="small" onClick={onCancelClick} className={styles.desktopCloseButton}>
        <CloseIcon />
      </IconButton>
      <div className={styles.dialogContent}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={resultImageUrl!} />
          <div className={styles.imageSubtitle}>твоя обложка</div>
        </div>
        <div className={styles.options}>
          {Object.values(ImageType).map((x) => (
            <OptionView key={x} type={x} active={imageType === x} activateOption={setImageType} />
          ))}
        </div>
        <LoadingButton
          className={styles.downloadButton}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onDownloadClick}
          loading={inProgress}
        >
          Скачать
        </LoadingButton>
      </div>
    </div>
  );
};

export default DownloadView;
