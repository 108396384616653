import { useAppDispatch, useAppSelector } from "@store/hooks";
import { openAppQrViewAction } from "@store/landing";
import { LayoutMode } from "@store/models";
import classNames from "classnames";
import { useCallback } from "react";
import ym from "react-yandex-metrika";
import * as styles from "./DownloadAppButton.module.scss";

const DownloadAppButton = ({ className }: { className?: string }) => {
  const dispatch = useAppDispatch();
  const layoutMode = useAppSelector((state) => state.landing.layoutMode);
  const onClick = useCallback(() => {
    ym("reachGoal", "download_app_button_click");
    if (layoutMode === LayoutMode.MOBILE) {
      window.open("https://redirect.appmetrica.yandex.com/serve/100424564185554841", "_blank");
    } else {
      dispatch(openAppQrViewAction());
    }
  }, [dispatch, layoutMode]);
  return (
    <div className={classNames(styles.root, className)} onClick={onClick}>
      <img src={require("@img/download_button.svg")} className={styles.image} />
    </div>
  );
};

export default DownloadAppButton;
