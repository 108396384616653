import { SerializedError } from "@reduxjs/toolkit";

export const trimUrl = (url: string): string => {
  const prefix = "api/v1/";
  const suffix = "?";
  let trimmed = url;
  const indexOfPrefix = trimmed.indexOf(prefix);
  if (indexOfPrefix > 0) {
    trimmed = trimmed.substring(indexOfPrefix + prefix.length);
  }
  const indexOfSuffix = trimmed.indexOf(suffix);
  if (indexOfSuffix > 0) {
    trimmed = trimmed.substring(0, indexOfSuffix);
  }
  return trimmed;
};

interface BetterResponse extends Response {
  request?: Request;
}

export const defaultResponseHandler = async (response: BetterResponse) => {
  const isJson = response.headers.get("content-type")?.includes("application/json");
  const data = isJson ? JSON.parse(await response.text()) : null;

  if (!response.ok) {
    return Promise.reject({
      code: `${response.status}`,
      message: data?.message,
      stack: [response.request?.method, trimUrl(response.url), `${response.status}`, data && data.message]
        .filter((x) => x)
        .join(" "),
    } as SerializedError);
  }
  return data;
};

export const jsonContentHeaders = {
  "Content-Type": "application/json",
};
