import { TaskStatus } from "@api/generate";
import animationData from "@img/animation.json";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { LayoutMode } from "@store/models";
import { checkTaskStatusAction } from "@store/thunks";
import Lottie from "lottie-web";
import { useEffect, useRef } from "react";
import Button from "./BetterButton";
import * as styles from "./GenerationProgressView.module.scss";
import BaseLayout from "./Landing/BaseLayout";

const taskStatusPollingPeriodMs = 2000;

const GenerationProgressView = () => {
  const dispatch = useAppDispatch();
  const layoutMode = useAppSelector((state) => state.landing.layoutMode);
  const lottieContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieContainerRef.current as Element,
      animationData,
      renderer: "canvas",
    });
    return () => {
      Lottie.destroy();
    };
  }, [layoutMode]);
  useEffect(() => {
    let unmounted = false;
    let timeoutHandle: NodeJS.Timeout | null = null;
    const checkStatusAndReschedule = () => {
      dispatch(checkTaskStatusAction()).then((result) => {
        if (unmounted) {
          return;
        }
        if (
          checkTaskStatusAction.rejected.match(result) ||
          (checkTaskStatusAction.fulfilled.match(result) && result.payload.task_status === TaskStatus.PENDING)
        ) {
          timeoutHandle = setTimeout(checkStatusAndReschedule, taskStatusPollingPeriodMs);
        }
      });
    };
    checkStatusAndReschedule();
    return () => {
      unmounted = true;
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [dispatch]);
  return (
    <BaseLayout
      imageElement={<div className={styles.lottieContainer} ref={lottieContainerRef} />}
      imageSubtitleText=""
      headingText={layoutMode === LayoutMode.DESKTOP ? "Генерация обложек" : "Генерация"}
      subheadingText={layoutMode === LayoutMode.DESKTOP ? "Быстро и просто" : "Работаем..."}
      buttonsElement={
        <div className={styles.buttonsContainer}>
          <Button variant="contained" color="success" fullWidth disabled>
            Скачать
          </Button>
          <Button variant="outlined" color="secondary" fullWidth disabled>
            Создать еще
          </Button>
        </div>
      }
      desktopSecondaryHeadingText="Генерация"
      desktopSecondarySubheadingText="Работаем..."
      footerMode="download"
    />
  );
};

export default GenerationProgressView;
