// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vszvq60A90xpSasrCAmS{position:fixed;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center}.JUUrwcoJmRiaXtUhvrYA{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".root {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.item {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var root = "Vszvq60A90xpSasrCAmS";
export var item = "JUUrwcoJmRiaXtUhvrYA";
export default ___CSS_LOADER_EXPORT___;
