// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HxDJaSVQFlWozOiQodKR,.f2JBd1BZYOruibUEL7tn{width:var(--image-size);height:var(--image-size)}button.gLOZW6YRWHSSTArTEtDF{width:280px}@media(min-width: 821px){button.gLOZW6YRWHSSTArTEtDF{max-width:220px}}", "",{"version":3,"sources":["webpack://./src/app/DefaultView.module.scss","webpack://./src/partials/_breakpoints.scss"],"names":[],"mappings":"AAEA,4CAEE,uBAAA,CACA,wBAAA,CAGF,4BACE,WAAA,CCcE,yBDfJ,4BAII,eAAA,CAAA","sourcesContent":["@use \"~partials/breakpoints\";\n\n.imageContainer,\n.image {\n  width: var(--image-size);\n  height: var(--image-size);\n}\n\nbutton.generateButton {\n  width: 280px;\n\n  @include breakpoints.gt(\"sm\") {\n    max-width: 220px;\n  }\n}\n","$sm: 821px;\n$md: 992px;\n$lg: 1200px;\n\n$breakpoints: (\n  \"sm\": $sm,\n  \"md\": $md,\n  \"lg\": $lg,\n) !default;\n\n@mixin lt($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (max-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  } @else {\n    @warn \"Unfortunately, no value could be retrieved from `#{$breakpoint}`. \"\n        + \"Available breakpoints are: #{map-keys($breakpoints)}.\";\n  }\n}\n\n@mixin gt($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  } @else {\n    @warn \"Unfortunately, no value could be retrieved from `#{$breakpoint}`. \"\n        + \"Available breakpoints are: #{map-keys($breakpoints)}.\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var imageContainer = "HxDJaSVQFlWozOiQodKR";
export var image = "f2JBd1BZYOruibUEL7tn";
export var generateButton = "gLOZW6YRWHSSTArTEtDF";
export default ___CSS_LOADER_EXPORT___;
