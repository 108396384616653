import punchLogo from "@img/ic-punch-logo.svg";
import classNames from "classnames";
import * as styles from "./BaseDesktopLayout.module.scss";
import DownloadAppButton from "./DownloadAppButton";
import { BaseLayoutProps } from "./models";

const BaseDesktopLayout = ({
  imageElement,
  imageSubtitleText,
  subheadingText,
  headingText,
  buttonsElement,
  desktopSecondaryHeadingText,
  desktopSecondarySubheadingText,
  footerMode,
}: BaseLayoutProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.contentRoot}>
        <div className={styles.left}>
          <div className={styles.imageContainer}>{imageElement}</div>
          <div className={styles.imageSubtitle}>{imageSubtitleText}</div>
          {desktopSecondaryHeadingText && <div className={styles.secondaryHeading}>{desktopSecondaryHeadingText}</div>}
          {desktopSecondarySubheadingText && (
            <div className={styles.secondarySubheading}>{desktopSecondarySubheadingText}</div>
          )}
        </div>
        <div className={styles.right}>
          <a href="https://punch.ru/" className={styles.logoLink} target="_blank" rel="noreferrer noopener">
            <img src={punchLogo} className={styles.logo} alt="ПАНЧ лого" />
          </a>
          <div className={styles.heading}>{headingText}</div>
          <div className={styles.subheading}>{subheadingText}</div>
          <div className={styles.buttonsContainer}>{buttonsElement}</div>
          <DownloadAppButton
            className={classNames(styles.downloadAppButton, footerMode === "default" && styles.hidden)}
          />
        </div>
      </div>
    </div>
  );
};

export default BaseDesktopLayout;
