import { ruRU } from "@mui/material/locale";
import { createTheme, PaletteOptions, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import * as styles from "./AppTheme.module.scss";

const themeBuilder = (palette: Partial<PaletteOptions>) =>
  createTheme(
    {
      palette: {
        mode: "dark",
        ...palette,
      },
      shape: {
        borderRadius: 12,
      },
      components: {
        // https://github.com/mui/material-ui/issues/26664#issuecomment-1064038365
        // https://esnteam.atlassian.net/browse/WA-116
        MuiPaper: { defaultProps: { sx: { "& *:focus": { outline: "none" } } } },
        MuiButton: {
          defaultProps: { sx: { textTransform: "none", boxShadow: "none !important" } },
        },
      },
    },
    ruRU
  );

const commonPaletteOptions = {
  secondary: { main: "rgba(255, 255, 255, 0.9)" },
  error: { main: "#F24822" },
  success: { main: "#9CFF33" },
  warning: { main: "#FF9500" },
};

const defaultTheme = themeBuilder({
  primary: { main: "#9CFF33" },
  ...commonPaletteOptions,
});

const AppTheme = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    document.body.className = styles.punch;
  }, []);
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};

export default AppTheme;
