// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qoVLYHJzZ3tl2UFcWzW1{width:var(--image-size);height:var(--image-size);background:rgba(26,26,26,.6);border-radius:32px;filter:drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.5))}.LrR9kf8t9se8rJaaXjkA{display:grid;grid-template-columns:repeat(2, 132px);grid-column-gap:16px}", "",{"version":3,"sources":["webpack://./src/app/GenerationProgressView.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CACA,wBAAA,CACA,4BAAA,CACA,kBAAA,CACA,mDAAA,CAGF,sBACE,YAAA,CACA,sCAAA,CACA,oBAAA","sourcesContent":[".lottieContainer {\n  width: var(--image-size);\n  height: var(--image-size);\n  background: rgba(26, 26, 26, 0.6);\n  border-radius: 32px;\n  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.5));\n}\n\n.buttonsContainer {\n  display: grid;\n  grid-template-columns: repeat(2, 132px);\n  grid-column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export var lottieContainer = "qoVLYHJzZ3tl2UFcWzW1";
export var buttonsContainer = "LrR9kf8t9se8rJaaXjkA";
export default ___CSS_LOADER_EXPORT___;
