import { defaultResponseHandler, jsonContentHeaders } from "./utils";

export interface UpscaleResponse {
  url: string;
}

export const upscaleImage = ({ imageUrl, token }: { imageUrl: string; token: string }): Promise<UpscaleResponse> =>
  fetch(`${process.env.API_URL}/api/v1/upscaler/upscale`, {
    method: "POST",
    body: JSON.stringify({ image_url: imageUrl }),
    headers: {
      Authorization: `Bearer ${token}`,
      ...jsonContentHeaders,
    },
  }).then(defaultResponseHandler);

export const upscaleImageToStoriesFormat = ({
  imageUrl,
  token,
  trackName,
  artistName,
}: {
  imageUrl: string;
  token: string;
  trackName?: string | null;
  artistName?: string | null;
}): Promise<UpscaleResponse> =>
  fetch(`${process.env.API_URL}/api/v1/upscaler/to_stories`, {
    method: "POST",
    body: JSON.stringify({ image_url: imageUrl, track_name: trackName || "", artist_name: artistName || "" }),
    headers: {
      Authorization: `Bearer ${token}`,
      ...jsonContentHeaders,
    },
  }).then(defaultResponseHandler);
