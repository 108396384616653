import { useEffect, useState } from "react";

interface UseCountdownProps {
  countdownTo: number;
  enabled: boolean;
  key?: any;
}

export default ({ countdownTo, enabled, key }: UseCountdownProps) => {
  const [countdownValue, setCountdownValue] = useState(Math.max(0, countdownTo));
  useEffect(() => {
    if (!enabled || countdownTo < 0) {
      setCountdownValue(0);
      return;
    }
    setCountdownValue(countdownTo);
    const intervalHandle = setInterval(() => {
      setCountdownValue((current) => Math.max(0, current - 1));
    }, 1000);
    return () => clearInterval(intervalHandle);
  }, [enabled, countdownTo, key]);
  return countdownValue;
};
