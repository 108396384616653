import errorImage from "@img/error_image.png";
import * as styles from "./ErrorView.module.scss";

const ErrorView = () => {
  return (
    <div className={styles.root}>
      <img src={errorImage} className={styles.image} />
      <div className={styles.title}>Попробуй зайти позже</div>
      <div className={styles.subtitle}>Так много артистов, которым нужны обложки, что сервер прилёг.</div>
    </div>
  );
};

export default ErrorView;
