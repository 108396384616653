import { RefreshDto } from "@api/authorization";
import { createSlice, SerializedError } from "@reduxjs/toolkit";
import { AuthorizationPhase, TokenType } from "./models";
import {
  ensureAnonymousTokenAction,
  ensureDeviceUuidAction,
  ensureFreshTokenAction,
  sendValidationCodeAction,
  validateCodeAction,
} from "./thunks";

interface AuthorizationState {
  authorizationPhase: AuthorizationPhase;
  deviceUuid: string | null;
  tokenData: RefreshDto | null;
  tokenType: TokenType;
  /** for restarting timer */
  codeValidationNetworkRequestId: string | null;
  /** 10 digits */
  phoneNumber: string | null;
  /** 10 digits formatted like xxx xxx xxxx */
  formattedPhoneNumber: string | null;
  codeValidationError: SerializedError | null;
  codeRequestError: SerializedError | null;
}

const defaultState: AuthorizationState = {
  authorizationPhase: AuthorizationPhase.PHONE_NUMBER,
  deviceUuid: null,
  tokenData: null,
  tokenType: TokenType.ANON,
  codeValidationNetworkRequestId: null,
  phoneNumber: null,
  formattedPhoneNumber: null,
  codeValidationError: null,
  codeRequestError: null,
};

const name = "authorization";

const authorizationStateSlice = createSlice({
  name,
  initialState: defaultState,
  reducers: {
    reenterPhoneNumberAction: (state) => {
      state.authorizationPhase = AuthorizationPhase.PHONE_NUMBER;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ensureDeviceUuidAction.fulfilled, (state, action) => {
      state.deviceUuid = action.payload;
    });
    builder.addCase(ensureAnonymousTokenAction.fulfilled, (state, action) => {
      state.tokenData = action.payload;
      state.tokenType = TokenType.ANON;
    });
    builder.addCase(ensureFreshTokenAction.fulfilled, (state, action) => {
      state.tokenData = action.payload;
    });
    builder.addCase(ensureFreshTokenAction.rejected, () => {
      return defaultState;
    });
    builder.addCase(sendValidationCodeAction.pending, (state, action) => {
      state.formattedPhoneNumber = action.meta.arg.formattedPhoneNumber;
      state.phoneNumber = action.meta.arg.phoneNumber;
      state.codeValidationNetworkRequestId = action.meta.requestId;
    });
    builder.addCase(sendValidationCodeAction.fulfilled, (state) => {
      state.authorizationPhase = AuthorizationPhase.CODE;
    });
    builder.addCase(sendValidationCodeAction.rejected, (state, action) => {
      state.codeRequestError = action.error;
      state.authorizationPhase = AuthorizationPhase.PHONE_NUMBER;
    });
    builder.addCase(validateCodeAction.rejected, (state, action) => {
      state.codeValidationError = action.error;
    });
    builder.addCase(validateCodeAction.fulfilled, (state, action) => {
      state.tokenData = action.payload;
      state.tokenType = TokenType.USER;
    });
  },
});

export const { reenterPhoneNumberAction } = authorizationStateSlice.actions;

export default authorizationStateSlice.reducer;
