import sampleSize from "lodash.samplesize";

export enum LayoutMode {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum ViewMode {
  DEFAULT = "DEFAULT",
  GENERATOR_SETTINGS = "GENERATOR_SETTINGS",
  AUTHORIZATION = "AUTHORIZATION",
  GENERATION_PROGRESS = "GENERATION_PROGRESS",
  GENERATION_RESULT = "GENERATION_RESULT",
  DOWNLOAD = "DOWNLOAD",
  DISCLAIMER = "DISCLAIMER",
  APP_LINK_QR = "APP_LINK_QR",
}

export enum AuthorizationPhase {
  PHONE_NUMBER = "PHONE_NUMBER",
  CODE = "CODE",
}

export enum TokenType {
  ANON = "ANON",
  USER = "USER",
}

export interface TokenData {
  token: string;
  expiration: number;
}

export enum StyleType {
  ILLUSTRATION = "illustration",
  PHOTOGRAPHY = "photography",
  THREE_D_RENDER = "3d render",
  PAINTING = "painting",
  PHOTOREALISM = "photorealism",
}

export enum MoodType {
  happy = "happy",
  romantic = "romantic",
  sad = "sad",
  melancholic = "melancholic",
  energetic = "energetic",
  angry = "angry",
  calm = "calm",
  relaxing = "relaxing",
  peaceful = "peaceful",
  chill = "chill",
  upbeat = "upbeat",
  uplifting = "uplifting",
  dark = "dark",
  scary = "scary",
  creepy = "creepy",
  intense = "intense",
  epic = "epic",
  mysterious = "mysterious",
  dreamy = "dreamy",
}

export enum GenreType {
  pop = "pop",
  synthwave = "synthwave",
  rock = "rock",
  hip_hop = "hip hop",
  pop_rock = "pop rock",
  trap = "trap",
  old_school = "old school",
  acoustic = "acoustic",
  guitar = "guitar",
  piano = "piano",
  phonk = "phonk",
  reggaeton = "reggaeton",
  drill = "drill",
  tropical_pop = "tropical pop",
  cloud_rap = "cloud rap",
  dance = "dance",
  garage = "garage",
  house = "house",
  moombahton = "moombahton",
  bass_house = "bass house",
  tropical_house = "tropical house",
  boom_bap = "boom bap",
  deep_house = "deep house",
  future_house = "future house",
  rave = "rave",
  progressive_tech = "progressive tech",
  hookah_rap = "hookah rap",
  g_house = "g house",
  rnb = "rnb",
  hyperpop = "hyperpop",
  lo_fi = "Lo Fi",
  lyrical = "lyrical",
  drum_and_bass = "drum and bass",
  rap = "rap",
  chanson = "chanson",
  synthpop = "synthpop",
  afrobeat = "afrobeat",
  funk = "funk",
}

export interface GeneratorSetting {
  content: string | null;
  style: StyleType | null; // one of styles array
  mood: MoodType[];
  genre: GenreType[];
  punchify: boolean;
  title: string;
  subtitle: string;
}

export const defaultSetting: GeneratorSetting = {
  content: "",
  style: null,
  mood: [],
  genre: [],
  punchify: false,
  title: "",
  subtitle: "",
};

const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const makeRandomSetting = (setting: GeneratorSetting): GeneratorSetting => ({
  ...setting,
  style: sampleSize(Object.values(StyleType), 1)[0],
  mood: sampleSize(Object.values(MoodType), getRandomInt(1, 5)),
  genre: sampleSize(Object.values(GenreType), getRandomInt(1, 5)),
  punchify: Math.random() > 0.5,
});
