// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jTSPyKtZIGmbBh_fQEQD{position:absolute;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center}.iGB5ZOMayaz7E_rUB4RD{display:flex;flex-direction:column;align-items:center;background-color:rgba(26,26,26,.9);padding:24px;border-radius:32px}.ipAFPVjHDB7zBHHhEb0z{font-family:\"DrukWideCyr\";font-weight:900;font-size:18px;line-height:24px;margin-bottom:8px}.ktxhqZ63qbF26zwICwrJ{margin-bottom:24px;font-weight:400;font-size:16px;line-height:20px;letter-spacing:.2px;color:rgba(255,255,255,.7)}.UWngJtMhIccVLWBIeYKE{margin-bottom:24px;width:280px;height:280px}", "",{"version":3,"sources":["webpack://./src/app/QrView.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,kCAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,yBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAGF,sBACE,kBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".root {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: rgba(26, 26, 26, 0.9);\n  padding: 24px;\n  border-radius: 32px;\n}\n\n.title {\n  font-family: \"DrukWideCyr\";\n  font-weight: 900;\n  font-size: 18px;\n  line-height: 24px;\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  margin-bottom: 24px;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 20px;\n  letter-spacing: 0.2px;\n  color: rgba(255, 255, 255, 0.7);\n}\n\n.qrImage {\n  margin-bottom: 24px;\n  width: 280px;\n  height: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
export var root = "jTSPyKtZIGmbBh_fQEQD";
export var content = "iGB5ZOMayaz7E_rUB4RD";
export var title = "ipAFPVjHDB7zBHHhEb0z";
export var subtitle = "ktxhqZ63qbF26zwICwrJ";
export var qrImage = "UWngJtMhIccVLWBIeYKE";
export default ___CSS_LOADER_EXPORT___;
