// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bNVP3IamyKESi5tRHR1M{border-radius:32px;width:var(--image-size);height:var(--image-size);border-radius:32px;filter:drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.5))}.fgWQ50QENiM6zeTSP4fR{display:grid;grid-template-columns:repeat(2, 132px);grid-column-gap:16px}", "",{"version":3,"sources":["webpack://./src/app/GenerationResultView.module.scss"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,uBAAA,CACA,wBAAA,CACA,kBAAA,CACA,mDAAA,CAGF,sBACE,YAAA,CACA,sCAAA,CACA,oBAAA","sourcesContent":["@use \"~partials/breakpoints\";\n\n.resultImage {\n  border-radius: 32px;\n  width: var(--image-size);\n  height: var(--image-size);\n  border-radius: 32px;\n  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.5));\n}\n\n.buttonsContainer {\n  display: grid;\n  grid-template-columns: repeat(2, 132px);\n  grid-column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export var resultImage = "bNVP3IamyKESi5tRHR1M";
export var buttonsContainer = "fgWQ50QENiM6zeTSP4fR";
export default ___CSS_LOADER_EXPORT___;
