import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import { useAppDispatch } from "@store/hooks";
import { cancelAuthorizationAction } from "@store/landing";
import { useCallback } from "react";
import * as styles from "./AuthorizationLayout.module.scss";

interface AuthorizationLayoutProps {
  inputElement: React.ReactNode;
  inputSubtitle: string | React.ReactNode;
  buttonElement: React.ReactNode;
  footerElement: React.ReactNode;
}

const AuthorizationLayout = ({
  inputElement,
  inputSubtitle,
  buttonElement,
  footerElement,
}: AuthorizationLayoutProps) => {
  const dispatch = useAppDispatch();
  const onCancelClick = useCallback(() => {
    dispatch(cancelAuthorizationAction());
  }, [dispatch]);
  return (
    <div className={styles.root}>
      <div className={styles.dialogTitleRow}>
        <div className={styles.dialogTitleText}>Авторизация</div>
        <IconButton size="small" onClick={onCancelClick} className={styles.mobileCloseButton}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      <IconButton size="small" onClick={onCancelClick} className={styles.desktopCloseButton}>
        <CloseIcon />
      </IconButton>
      <div className={styles.dialogContent}>
        <div className={styles.inputContainer}>{inputElement}</div>
        <div className={styles.inputSubtitle}>{inputSubtitle}</div>
        <div className={styles.buttonsContainer}>{buttonElement}</div>
        <div className={styles.footerElement}>{footerElement}</div>
      </div>
    </div>
  );
};

export default AuthorizationLayout;
